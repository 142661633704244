import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'utils/debounce';
import { UPDATE_CART } from 'zustand-lib/InterfaceZustand';
import useCartStates, { useCartActions } from 'zustand-lib/useCart';

const REGEX_NUMBER = new RegExp('^[0-9]+$');

export default function useCartControlTable(product, inputRef) {
  const [currentValue, setCurrentValue] = useState(0);
  const [valueChange, setValueChange] = useState(0);
  const [isShowModalRemove, setIsShowModalRemove] = useState(false);

  const { cartData, lastUpdatedIDRef } = useCartStates();
  const { updateCartItem, removeCartItem, getIDRef, getSKUfromIDRef } = useCartActions();

  const { current: idRef } = useRef(getIDRef(product));

  // Ensure that the input value is always in sync with the cartData
  // Use case: When there are many cards of the same product in the same page, and the user changes the quantity of one card, the quantity of the other cards should also be updated
  useEffect(
    function syncQuantity() {
      if (idRef === lastUpdatedIDRef) return; // Don't update itself
      if (getSKUfromIDRef(idRef) !== product.sku) return; // Don't update if this is not its product

      const productInCart = cartData
        .map((group) => group.products)
        .flat()
        .find((p) => p.skuCode === product.sku);

      const quantityInCart = productInCart?.quantityInCart || 0;

      if (inputRef.current && +inputRef.current.value !== quantityInCart) {
        inputRef.current.value = String(quantityInCart);
        setCurrentValue(quantityInCart);
        setValueChange(quantityInCart);
      }
    },
    [cartData],
  );

  const productQuantity = product.quantity || 0;

  const handleCart = (val, type) => {
    const params = {
      idRef,
      newQuantity: +val,
      product: {
        sku: product.sku,
        type: product.type,
        name: product.name,
        productId: product.productId,
        sellerCode: product.sellerCode,
        blockCode: product?.blockCode,
        // sellerID: product.sellerId,
        // eventSource: product.eventSource,
        // recommendSKUs: product.recommendSKUs,
        // metric: product.metric,
        ...(type === UPDATE_CART.INCREASE && { quantity: currentValue, isDeal: false, price: product.displayPrice }),
        ...(type === UPDATE_CART.DECREASE && { quantity: currentValue, isDeal: false, price: product.displayPrice }),
        ...(type === UPDATE_CART.REMOVE && { slug: product.slug, salePrice: '', cartItemType: '' }),
      },
    };

    switch (type) {
      case UPDATE_CART.INCREASE:
        return updateCartItem({ type: UPDATE_CART.INCREASE, ...params });
      case UPDATE_CART.DECREASE:
        return updateCartItem({ type: UPDATE_CART.DECREASE, ...params });
      case UPDATE_CART.REMOVE:
        return removeCartItem({ type: UPDATE_CART.REMOVE, ...params });
    }
  };

  const handler = useCallback(
    debounce((val, updateType) => handleCart(val, updateType), 400),
    [product],
  );

  const confirmRemove = () => {
    inputRef.current.value = 0;
    setValueChange(0);
    setCurrentValue(0)
    setIsShowModalRemove(false);
    return handler(0, UPDATE_CART.REMOVE);
  };

  const closeModal = () => {
    inputRef.current.value = 1;
    setValueChange(1);
    setCurrentValue(1);
    handler(1, UPDATE_CART.DECREASE);
    setIsShowModalRemove(false);
  };

  const handleDecrease = (e) => {
    e.stopPropagation();
    const newValue = inputRef.current.value - 1;
    if (newValue >= 0) {
      if (newValue === 0) {
        return setIsShowModalRemove(true);
      }
      inputRef.current.value = newValue;
      setValueChange(newValue);
      return handler(newValue, UPDATE_CART.DECREASE);
    }
    return null;
  };

  const handleIncrease = (e) => {
    e.stopPropagation();
    const newValue = +inputRef.current.value + 1;
    if (newValue > product?.consumedMaxQuantity?.maxQuantityConsumed) return null;
    setValueChange(newValue);
    inputRef.current.value = newValue;
    return handler(newValue, UPDATE_CART.INCREASE);
  };

  const handleBlurInput = (e) => {
    const { value } = e.target;
    if (+value !== +currentValue) {
      if (+value && REGEX_NUMBER.test(value)) {
        return handler(value, +value > +currentValue ? UPDATE_CART.INCREASE : UPDATE_CART.DECREASE);
      }
    }
    if (+value === 0 && +value !== valueChange) {
      return setIsShowModalRemove(true);
    }
    return null;
  };

  const handleChangeInput = (e) => {
    const { value } = e.target;
    if (value === '00') {
      inputRef.current.value = 0;
      return;
    }
    if (value && inputRef.current.value !== '0') {
      if (+value > +product?.consumedMaxQuantity?.maxQuantityConsumed || +value > 10000) {
        const valueData = +product?.consumedMaxQuantity?.maxQuantityConsumed || 10000;
        inputRef.current.value = valueData;
        setValueChange(valueData);
      } else if (REGEX_NUMBER.test(+value)) {
        inputRef.current.value = parseInt(value, 10);
        setValueChange(parseInt(value, 10));
      } else {
        inputRef.current.value = 0;
        setValueChange(0);
      }
    } else {
      inputRef.current.value = 0;
    }
  };

  useEffect(() => {
    setCurrentValue(productQuantity);
    setValueChange(productQuantity);
    inputRef.current.value = productQuantity;
  }, [productQuantity]);

  return [handleIncrease, handleBlurInput, handleChangeInput, handleDecrease, confirmRemove, closeModal, isShowModalRemove, valueChange];
}
